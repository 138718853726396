//AWS and REACT Libraries 

import { Authenticator } from '@aws-amplify/ui-react';

//page components
import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';

//boostrap components 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoggedInBanner from '../components/LoggedInBanner.js';


export const UserPage = () => {
  return (

    <div className='App'>
      <Banner/>
      <NavMenu/> 

      <Container>
          <Row>
            <Col  xs={12} md={12} lg={12} xl={12}>
              <div className='leftContent text-center'>
                <h2 className='UserNewsDashboard emphTitle padding'>LOG IN</h2>
               
              </div>              
            </Col>
            <Col  xs={12} md={12} lg={12} xl={12}>
            <Authenticator className='login_container container' loginMechanisms={['email']} hideSignUp> 
                <h3 className='notice whiteFont empTitle text-center'> You are now logged into the Metro Housing Boston Property manager Portal</h3>
                <LoggedInBanner/>
              </Authenticator>       
            </Col>
          </Row>

        </Container>
      <SITELINKS/> 
    
    </div>
  );
};