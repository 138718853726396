
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/

import metrologo from '../images/mhb-site-logo.png';
import React from 'react';

export default function Title()
{
    return(
        <div className='m_banner'>
            <img src={metrologo} className='mLogo' alt="mhb-site-logo.png" />
            <h2 className=''>Property Manager Payment Portal</h2>
        </div>

    );
}