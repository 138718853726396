/*

    <a href='https://airtable.com/shrzMcypjWPdZaeLL'>Rent Roll Increase</a>
    <a href='portalhelp@mbhp.org'>Account Support/Assistance</a>
    <a href='gateway@metrohousingboston.org'>Questions on Account</a>
*/
import { Table } from 'react-bootstrap';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
/**
 * Navigation Menu section for Links on website
 * @returns 
 * 
 *    
 * Removed Code Below (Header Logo icon on left)         
<a className='navbar-brand' href='/#'>
    Metro Housing Boston
</a>
 */

const exportExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'MHBPayments');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, `${fileName}.xlsx`);
  };

const fetchPayments = async (page, limit, source) => {
    try {
        const response = await axiosInstance.post('/payments', {
            page,
            limit,
            source
        });
        return response.data;
    } catch (error) {
        console.log('Error fetching payments:', error);
    }
};

export default function Payments() {
    const [payments, setPayments] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, limit: 25 });
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const [hPayments, setHPayments] = useState([]);
    const [hPagination, setHPagination] = useState({ page: 1, limit: 25 });
    const [hLoading, setHLoading] = useState(false);
    const [hTotalCount, setTotalHCount] = useState(0);

    const [mPayments, setMPayments] = useState([]);
    const [mPagination, setMPagination] = useState({ page: 1, limit: 25 });
    const [mLoading, setMLoading] = useState(false);
    const [mTotalCount, setTotalMCount] = useState(0);

    const [rPayments, setRPayments] = useState([]);
    const [rPagination, setRPagination] = useState({ page: 1, limit: 25 });
    const [rLoading, setRLoading] = useState(false);
    const [rTotalCount, setTotalRCount] = useState(0);

    const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
     };


    const renderDate = (date) => {
        return date && moment(date).isValid() ? moment(date).utc().format('MM-DD-YYYY') : '';
    }

    const renderAmount = (amount) => {
        return amount && amount.startsWith("$") ? amount : `$${amount}`;
    }
    
    useEffect(() => {
        setLoading(true);
        fetchPayments(pagination.page, pagination.limit, "S8PAYMENTS")
            .then(result => {
                setLoading(false);
                if (result && result.success) {
                    setPayments(result.items);
                    setTotalCount(result.totalCount);
                }
            }).catch(error => {
                setLoading(false);
                console.error(error);
            });
    }, [pagination]);

    useEffect(() => {
        setHLoading(true);
        fetchPayments(hPagination.page, hPagination.limit, "HAPPYPAYMENTS")
            .then(result => {
                setHLoading(false);
                if (result && result.success) {
                    setHPayments(result.items);
                    setTotalHCount(result.totalCount);
                }
            }).catch(error => {
                setHLoading(false);
                console.error(error);
            });
    }, [hPagination]);

    useEffect(() => {
        setMLoading(true);
        fetchPayments(mPagination.page, mPagination.limit, "MRVPPAYMENTS")
            .then(result => {
                setMLoading(false);
                if (result && result.success) {
                    setMPayments(result.items);
                    setTotalMCount(result.totalCount);
                }
            }).catch(error => {
                setMLoading(false);
                console.error(error);
            });
    }, [mPagination]);

    useEffect(() => {
        setRLoading(true);
        fetchPayments(rPagination.page, rPagination.limit, "RENTSHARE")
            .then(result => {
                setRLoading(false);
                if (result && result.success) {
                    setRPayments(result.items);
                    setTotalRCount(result.totalCount);
                }
            }).catch(error => {
                setRLoading(false);
                console.error(error);
            });
    }, [rPagination]);

    return ( 
        <div className='userdata paymentsdata'>
            <div className='rentShareUpdate payments'  >
                 <h3 className='text-center whiteFont payments-heading'>2024 - 2025 PAYMENTS</h3>
                 <div className='exportOptions hide'>
                    <h5 className='text-center emphTitle'>EXPORT OPTIONS</h5>
                    <div className='emphTitle margin'>
                        <input 
                            type="radio"
                            value="Option 1"
                            checked={selectedOption === 'Option 1'}
                            onChange={handleOptionChange}
                        />
                               2025 PAYMENTS
                    </div>
                    <button className='exportDataBTN btn btn-primary' onClick={() => exportExcel(payments, '2024 S8 Payments')}>Export S8</button>
                    <button className='exportDataBTN btn btn-primary' onClick={() => exportExcel(hPayments, '2024 RAFT Payments')}>Export RAFT</button>
                    <button className='exportDataBTN btn btn-primary' onClick={() => exportExcel(mPayments, '2024 MRVP Payments')}>Export MRVP</button>
                    <hr></hr>
                    <br></br>
                    <button className='exportDataBTN btn btn-primary' onClick={() => exportExcel(mPayments, '2024 MRVP Payments')}>Export All</button>
                    <br></br>
                    <br></br>

                </div>
                <br></br>
                <h3 className='userdataLabel emphTitle'>RENT SHARE UPDATES 2025</h3>
                {
                    rLoading ? (<div>Loading...</div>) : (
                        <table className="table ">
                            <thead >
                                <tr>
                                    <th className='tableheader' scope="col">EFFECTIVE DATE</th>
                                    <th className='tableheader' scope="col">TENANT AMOUNT</th>
                                    <th className='tableheader' scope="col">HAP AMOUNT</th>         
                                    <th className='tableheader' scope="col">TOTAL AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rPayments.map((payment, index) => (
                                    <tr key={index}>
                                        <td>{renderDate(payment.EFFECTIVEDATE)}</td>
                                        <td>{renderAmount(payment.TENANTAMOUNT)}</td>
                                        <td>{renderAmount(payment.HAPAMOUNT)}</td>
                                        <td>{renderAmount(payment.TOTALAMOUNT)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>       
                    )
                }
                <div className="pagination">
                    <button onClick={() => setRPagination({ ...rPagination, page: rPagination.page - 1 })} disabled={rPagination.page === 1}>Previous</button>
                    <button onClick={() => setRPagination({ ...rPagination, page: rPagination.page + 1 })} disabled={rPagination.page * rPagination.limit >= rTotalCount}>Next</button>
                </div>
                <hr></hr>
            </div>
            
            <div className='payments'>
                <h3 className='userdataLabel emphTitle' >S8 PAYMENTS</h3>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <Table className="table">
                        <thead>
                            <tr>
                                <th className='nopadding tableheader' scope="col">OWNER #</th>
                                <th className='nopadding tableheader' scope="col">TAX ID </th>
                                <th className='nopadding tableheader' scope="col">CHECKDATE</th>
                                <th className='nopadding tableheader' scope="col">CHECK NUMBER</th>           
                                <th className='nopadding tableheader' scope="col">AMOUNT</th>
                                <th className='nopadding tableheader' scope="col">ADDRESS</th>
                                <th className='nopadding tableheader' scope="col">CITY</th>
                                <th className='nopadding tableheader' scope="col">TENANT NAME</th>
                                <th className='nopadding tableheader' scope="col">TRANSACTION TYPE</th>
                            </tr>
                        </thead>
                        <tbody className='tableBody'>
                            {payments.map((payment, index) => (
                                <tr key={index}>
                                    <td className='nopadding'>{payment.OWNER_NUMBER}</td>
                                    <td className='nopadding'>{payment.PAYMENT_TAXID}</td>
                                    <td className='nopadding'>{renderDate(payment.CHECKDATE)}</td>
                                    <td className='nopadding'>{payment.CHECKNUM}</td>
                                    <td className='nopadding'>{renderAmount(payment.AMOUNT)}</td>
                                    <td className='nopadding'>{payment.ADDRESS}</td>
                                    <td className='nopadding'>{payment.CITY}</td>
                                    <td className='nopadding'>{payment.TENNAME}</td>
                                    <td className='nopadding'>{payment.TRANSTYPE}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                <div className="pagination">
                    <button onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })} disabled={pagination.page === 1}>Previous</button>
                    <button onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })} disabled={pagination.page * pagination.limit >= totalCount}>Next</button>
                </div>
                <hr></hr>
            </div>

            <div className='payments'  >
                <h3 className='userdataLabel emphTitle' >RAFT/HOMEBASE PAYMENTS</h3>

                {hLoading ? (
                    <div>Loading...</div>
                ) : (
                    <Table className="table">
                        <thead>
                            <tr>
                                <th className='nopadding tableheader' scope="col">OWNER #</th>
                                <th className='nopadding tableheader' scope="col">TAX ID </th>
                                <th className='nopadding tableheader' scope="col">CHECKDATE</th>
                                <th className='nopadding tableheader' scope="col">CHECK NUMBER</th>           
                                <th className='nopadding tableheader' scope="col">AMOUNT</th>
                                <th className='nopadding tableheader' scope="col">ADDRESS</th>
                                <th className='nopadding tableheader' scope="col">CITY</th>
                                <th className='nopadding tableheader' scope="col">TENANT NAME</th>
                                <th className='nopadding tableheader' scope="col">TRANSACTION TYPE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hPayments.map((payment, index) => (
                                <tr className='' key={index}>
                                    <td className='nopadding'>{payment.OWNER_NUMBER}</td>
                                    <td className='nopadding'>{payment.PAYMENT_TAXID}</td>
                                    <td className='nopadding'>{renderDate(payment.CHECKDATE)}</td>
                                    <td className='nopadding'>{payment.CHECKNUM}</td>
                                    <td className='nopadding'>{renderAmount(payment.AMOUNT)}</td>
                                    <td className='nopadding'>{payment.ADDRESS}</td>
                                    <td className='nopadding'>{payment.CITY}</td>
                                    <td className='nopadding'>{payment.TENNAME}</td>
                                    <td className='nopadding'>{payment.TRANSTYPE}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                <div className="pagination">
                    <button onClick={() => setHPagination({ ...hPagination, page: hPagination.page - 1 })} disabled={hPagination.page === 1}>Previous</button>
                    <button onClick={() => setHPagination({ ...hPagination, page: hPagination.page + 1 })} disabled={hPagination.page * hPagination.limit >= hTotalCount}>Next</button>
                </div>
                <hr></hr>
            </div>

            <div className='payments'  >
                <h3 className='userdataLabel emphTitle' >MRVP PAYMENTS</h3>

                {mLoading ? (
                    <div>Loading...</div>
                ) : (
                    <Table className="table">
                        <thead>
                            <tr>
                                <th className='nopadding tableheader' scope="col">OWNER #</th>
                                <th className='nopadding tableheader' scope="col">TAX ID </th>
                                <th className='nopadding tableheader' scope="col">CHECKDATE</th>
                                <th className='nopadding tableheader' scope="col">CHECK NUMBER</th>           
                                <th className='nopadding tableheader' scope="col">AMOUNT</th>
                                <th className='nopadding tableheader' scope="col">ADDRESS</th>
                                <th className='nopadding tableheader' scope="col">CITY</th>
                                <th className='nopadding tableheader' scope="col">TENANT NAME</th>
                                <th className='nopadding tableheader' scope="col">TRANSACTION TYPE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mPayments.map((payment, index) => (
                                <tr key={index}>
                                    <td className='nopadding'>{payment.OWNER_NUMBER}</td>
                                    <td className='nopadding'>{payment.PAYMENT_TAXID}</td>
                                    <td className='nopadding'>{renderDate(payment.CHECKDATE)}</td>
                                    <td className='nopadding'>{payment.CHECKNUM}</td>
                                    <td className='nopadding'>{renderAmount(payment.AMOUNT)}</td>
                                    <td className='nopadding'>{payment.ADDRESS}</td>
                                    <td className='nopadding'>{payment.CITY}</td>
                                    <td className='nopadding'>{payment.TENNAME}</td>
                                    <td className='nopadding'>{payment.TRANSTYPE}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                <div className="pagination">
                    <button onClick={() => setMPagination({ ...mPagination, page: mPagination.page - 1 })} disabled={mPagination.page === 1}>Previous</button>
                    <button onClick={() => setMPagination({ ...mPagination, page: mPagination.page + 1 })} disabled={mPagination.page * mPagination.limit >= mTotalCount}>Next</button>
                </div>
                <hr></hr>

            </div>

        </div>
    );
}

/*

    <a href='https://airtable.com/shrzMcypjWPdZaeLL'>Rent Roll Increase</a>
    <a href='portalhelp@mbhp.org'>Account Support/Assistance</a>
    <a href='gateway@metrohousingboston.org'>Questions on Account</a>
*/
