
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/
import { Authenticator } from '@aws-amplify/ui-react';
import React from 'react';
import signuplogo from '../images/signuplogo.png';
import Pdf from '../images/MemoCD.pdf';


export default function aws_login_component()
{
    return(
        <div className='whiteFont padding'>               
            <h2 className='whiteFont emphTitle2 text-center'>Portal Updates</h2>
            <hr></hr>
            <br></br>
           <br></br>

            <Authenticator className='login_container container' loginMechanisms={['email']} hideSignUp> 
                {({ signOut, user}) => (
                    <div className='whiteFont'>
                        <div className='LoggedInUser'>        
                            <h3 className='emphTitle'>Welcome, <p className='whiteFont'> {user.attributes.email} </p></h3>
                            <br></br>
                            <hr></hr>
                            <br></br>
                        </div>
                        <div className='UserTips'>

                            <h4 className='emphTitle'>MHB News / Updates (Updated 1/10/2025)</h4>
                            <ul>
                                <li>
                                    <p> Portal Updated with Payments up to 1/10/25 in S8, MRVP and HAPPY Tables.<br></br></p> 
                                </li>

                                <li>
                                    <p> Further Portal Updates for January 2025 will be completed this week. Reach out to us for any questions.<br></br></p> 
                                </li>
                                
                                <li>    
                                    <p> Please see the attached Memo for the change in the Smoke and Carbon Monoxide detectors guidelines. This took effect on 12/29/24.  
                                        <br></br>
                                        <a href = {Pdf} target = '_blank'  rel='noreferrer'>Smoke and Carbon Monoxide Detectors Guidelines</a>
                                    </p> 
                                </li>

                                
                                <li className='hide'>
                                    <p>Newly Added Feature: Export functionality on Payments page. <a className='btn btn-primary' href='/payments'>Export Payments</a></p>
                                </li>
                                <li className='hide'>
                                    <p>Newly Added Feature: Submit Feedback on the Footer menu below. <a className='btn btn-primary' href='https://forms.office.com/Pages/ResponsePage.aspx?id=J8xXIgxdK0y6AnOQOwjqAHVPPCEmmcRLu9IABP8zZRBUQlFDU0RFQUxRRjdCTzY4NTZQWjVMS09SVC4u'>Submit Feedback</a></p>
                                </li>
                            </ul>
                            

                            <h4 className='emphTitle'>Previous Announcements</h4>
                            <ul>
                                <li>
                                    <p>We are addressing missing, duplicate, or invalid payments requests as they arrive. Please submit a ticket if you encounter any of these issues. <b></b></p> 
                                </li>
                            </ul>
                         
                            <h4 className='emphTitle'>Additional Announcements</h4>
                            <ul>
                                <li>
                                    <p>Coming Soon: Rent Share Updates Database will be added for 2025 Updates <b></b></p> 
                                </li>
                                <li>
                                    <p>Coming Soon: Sort Functionality for Payments Page <b></b></p> 
                                </li>
                                <li>
                                    <p>Coming Soon: Updated FAQ Page with new resources to contact at MHB<b></b></p> 
                                </li>
                                <li>
                                    <p>Coming Soon: Export Portal Payments Features as PDF and XLSX<b></b></p> 
                                </li>
                                <li>
                                    <p>Coming Soon: Document Download Features<b></b></p> 
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                )}
            </Authenticator>
            <img src={signuplogo} className='signuplogo padding' alt="metroBG.jpg" />
            
            <br></br>
            <br></br>
            
            <div className='text-center portalUpdateInfo'>
                <h3 className='whiteFont emphTitle2 text-center'>Portal Update Status: Updated 1/10/2025</h3>
                <br></br>
                <h5 className='whiteFont'>SECTION 8 PAYMENTS: 1/10/25</h5>
                <h5 className='whiteFont'>MRVP PAYMENTS: 1/10/25</h5>
                <h5 className='whiteFont'>RAFT/HOMEBASE PAYMENTS: 1/10/25</h5>
                <br></br>
            </div>
            
        </div>

    );
}